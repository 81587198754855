<template>
  <div class="panel-wrapper">
    <div class="panel-container ">
      <div v-if="createInsightStep==0">
        <div class="row justify-content-end steps">
          <b-iconstack font-scale="1.6" class="icons-stack">
            <b-icon stacked icon='clipboard' style="" scale="0.5"></b-icon>
            <b-icon stacked icon="circle"></b-icon>
          </b-iconstack>
          <p class="icons-text">Type</p>
          <hr class="steps-icons-hr">
          <b-iconstack font-scale="1.6" class="icons-stack" :style='isOpacityOn'>
            <b-icon stacked icon='clipboard' style="" scale="0.5"></b-icon>
            <b-icon stacked icon="circle"></b-icon>
          </b-iconstack>
          <p class="icons-text" :style='isOpacityOn'>Resource</p>
        </div>
        <div class="steps-title">
          <p class="select-type-header">Select Type</p>
        </div>
        <div class="row type-selectors">
          <insight-type-selector insightType="Scene" icon="insights-scene"></insight-type-selector>
          <insight-type-selector insightType="Image" icon="insights-image"></insight-type-selector>
          <insight-type-selector insightType="Report" icon="insights-report"></insight-type-selector>
        </div>
        <hr class='my-2'>
        <div class="row justify-content-end buttons">
          <b-button class="insight-type-button" @click="cancelInsightMode">Cancel</b-button>
          <b-button variant="primary" :disabled="isTypeEmpty" class="insight-type-button" @click="nextBtnClicked">
            <b-spinner v-if="creatingSceneInsight" small variant="primary"></b-spinner>
            <p v-else >Next</p>
          </b-button>
        </div>
      </div>
      <div v-else>
        <div class="row justify-content-end steps">
          <b-iconstack font-scale="1.6" class="icons-stack">
            <b-icon stacked icon="circle-fill" class="grey-circle"></b-icon>
            <b-icon stacked icon='check-circle-fill' scale="0.5"></b-icon>
          </b-iconstack>
          <p class="icons-text">Type</p>
          <hr class="steps-icons-hr">
          <b-iconstack font-scale="1.6" class="icons-stack" :style='isOpacityOn'>
            <b-icon stacked icon='clipboard' style="" scale="0.5"></b-icon>
            <b-icon stacked icon="circle"></b-icon>
          </b-iconstack>
          <p class="icons-text" :style='isOpacityOn'>Resource</p>
        </div>
        <div class="steps-title">
          <p class="select-type-header">Select or upload the resource to be displayed on your {{ typeSelected }} Insight</p>
        </div>
        <div class="row creation-panel">
          <image-view-creation-panel v-if="currentInsightType=='Image'"></image-view-creation-panel>
          <report-view-creation-panel v-if="currentInsightType=='Report'"></report-view-creation-panel>
          <div v-if="currentInsightType=='Report'" class="d-flex flex-row align-items-center mt-4 page-number">
            <p class="mr-2">Default Page Number</p>
            <b-form-input size="sm" type="number" class="insight-form-input" v-model="defaultPageNumber" />
        </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import InsightTypeSelector from './InsightTypeSelector';
import ImageViewCreationPanel from './ImageViewCreationPanel';
import ReportViewCreationPanel from './ReportViewCreationPanel';

export default {
  name: 'InsightTypeSelectionPanel',
  components: {
    InsightTypeSelector,
    ImageViewCreationPanel,
    ReportViewCreationPanel
  },
  data() {
    return {
      typeSelected: '',
      creatingResourceView: false,
      creatingSceneInsight: false,
      defaultPageNumber: 1
    };
  },
  computed: {
    isTypeEmpty() {
      return this.typeSelected == '';
    },
    isOpacityOn() {
      if(this.createInsightStep==0){
        return 'opacity:0.5;';
      }else{
        return 'opacity:1;';
      }
    },
    resourceSelected() {
      return this.currentInsightResource == null;
    },
    currentInsightType() {
      if (this.insightTypeSelected !== '') {
        return this.insightTypeSelected;
      } else {
        return this.currentInsight?.insight_type;
      }
    },
    currentInsightDefaultPage() {
      if (this.currentInsight && this.currentInsight?.reportview_set?.length>0 && this.currentInsight?.insight_type == 'Report') {
        return this.currentInsight.reportview_set[0].page_number;
      } 

      return 1;
    },
    ...mapGetters('project', ['insightTypeSelected','createInsightStep','currentInsights','currentInsight', 'editInsightMode','createInsightMode',
      'currentInsightResource'])
  },
  mounted() {
    if (this.createInsightMode) {
      this.setCreateInsightStep(0);
      this.$store.dispatch('project/setCurrentInsightResource', null);
      this.$store.dispatch('project/setInsightTypeSelected', '');
    }
    if (this.editInsightMode) {
      this.setCreateInsightStep(1);
    }

    this.defaultPageNumber = this.currentInsightDefaultPage;
  },
  methods: {
    async cancelInsightMode() {
      this.$store.dispatch('project/setCurrentInsightResource', null);
      this.$store.dispatch('project/setInsightTypeSelected', '');
      this.$store.dispatch('project/setCreateInsightMode', false);
      this.$store.dispatch('project/setEditInsightMode', false);
      this.setCreateInsightStep(0);
    },
    previousBtnClicked() {
      let currentStep = this.createInsightStep;
      this.setCreateInsightStep(--currentStep);
      this.$store.dispatch('project/setInsightTypeSelected', this.typeSelected);
    },
    async nextBtnClicked() {
      if (this.insightTypeSelected == 'Scene'){
        this.creatingSceneInsight = true;
        this.$emit('createInsight');
      } else {
        let currentStep = this.createInsightStep;
        this.setCreateInsightStep(++currentStep);
        if (this.createInsightMode) {
          this.$emit('createInsight');
        }
      }
    },
    async createImageView() {
      let currentResource = this.currentInsightResource;
      let study_image_data = null;

      this.creatingResourceView = true;

      if (currentResource.files) {
        const currentStudyImageDetails = {
          title: this.currentInsight.title,
          study_image: currentResource.files[0]['path'],
          project_id: Number(this.$route.params.id),
          study_id: Number(this.$route.params.study)
        };

        study_image_data = await this.$store.dispatch('project/addStudyImage', {
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          studyImageDetails: currentStudyImageDetails
        });
      } else {
        study_image_data = currentResource;
      }

      const currentImageViewDetails = {
        title: this.currentInsight.title,
        insight_id: this.currentInsight.id,
        study_image_id: study_image_data.id
      };

      if (this.currentInsight.imageview_set.length>0) {
        await this.$store.dispatch('project/updateImageView', {
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          insightId: this.currentInsight.id,
          imageViewId: this.currentInsight.imageview_set[0].id,
          insightDetails: currentImageViewDetails,
        });
        this.currentInsight.imageview_set[0].study_image = study_image_data;
      } else {
        let currentViewSet = await this.$store.dispatch('project/addImageView', {
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          insightId: this.currentInsight.id,
          insightDetails: currentImageViewDetails,
        });
        this.currentInsight.imageview_set[0] = currentViewSet;
      }

      this.setCurrentImage(study_image_data);      
      this.creatingResourceView = false;
      this.$store.dispatch('project/setCreateInsightMode', false);
      this.$store.dispatch('project/setEditInsightMode', false);
      this.$store.dispatch('project/setCurrentInsightResource', null);
      this.$store.dispatch('project/setInsightTypeSelected', '');
      this.setCreateInsightStep(0);
    },
    async createReportView() {
      let currentResource = this.currentInsightResource;
      let report_data = null;

      this.creatingResourceView = true;

      if (currentResource?.files) {
        let extensionIndex = currentResource?.files[0].path.name.lastIndexOf('.');
        let resourceTitle = currentResource?.files[0].path.name.slice(0, extensionIndex);

        const currentReportDetails = {
          title: resourceTitle,
          report: currentResource?.files[0].path,
          project_id: Number(this.$route.params.id),
          study_id: Number(this.$route.params.study)
        };

        report_data = await this.$store.dispatch('project/addReport', {
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          reportDetails: currentReportDetails
        });
      } else {
        report_data = currentResource;
      }

      const currentReportViewDetails = {
        title: this.currentInsight.title,
        insight_id: this.currentInsight.id,
        report_id: report_data.id,
        page_number: this.defaultPageNumber
      };

      if (this.currentInsight.reportview_set.length>0) {
        await this.$store.dispatch('project/updateReportView', {
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          insightId: this.currentInsight.id,
          reportViewId: this.currentInsight.reportview_set[0].id,
          insightDetails: currentReportViewDetails,
        });
        this.currentInsight.reportview_set[0].report = report_data;
        this.currentInsight.reportview_set[0].page_number = this.defaultPageNumber;
      } else {
        let currentViewSet = await this.$store.dispatch('project/addReportView', {
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          insightId: this.currentInsight.id,
          insightDetails: currentReportViewDetails,
        });
        this.currentInsight.reportview_set[0] = currentViewSet;
      }

      this.setCurrentReport(null); // to set off the currentReport() watcher on ReportContainer
      this.setCurrentReport(this.currentInsight.reportview_set[0].report);
      this.creatingResourceView = false;
      this.$store.dispatch('project/setCreateInsightMode', false);
      this.$store.dispatch('project/setEditInsightMode', false);
      this.$store.dispatch('project/setCurrentInsightResource', null);
      this.$store.dispatch('project/setInsightTypeSelected', '');
      this.setCreateInsightStep(0);
    },
    async saveBtnClicked() {

      let insightType = this.currentInsight?.insight_type;

      if (insightType == 'Image') {
        this.createImageView();
      } else if (insightType == 'Report') {
        this.createReportView();
      }
    },
    sceneInsightCreated() {
      this.creatingSceneInsight = false;
    },
    ...mapActions({
      setCreateInsightStep: 'project/setCreateInsightStep',
      setCurrentImage: 'project/setCurrentImage',
      setCurrentReport: 'project/setCurrentReport'
    })
  },
  watch: {
    insightTypeSelected(newValue) {
      this.typeSelected = newValue;
    },
    currentInsight(newValue) {
      if (newValue) {
        this.defaultPageNumber = this.currentInsightDefaultPage;
      }
    }
  }
};
</script>

<style scoped>

.insight-form-input {
 width: 15%;
}

.panel-wrapper {
  position: relative;
  margin: 0;
  flex-grow: 1;
  min-width: 31.25rem;
  height: calc(100vh - (var(--header-footer-height) * 2 + 1rem));
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
}

.panel-container {
  margin: 1.5rem 2rem 0rem 2rem;
}

.icons-stack {
  margin-top: 0;
  margin-right: 0.5rem;
}

.steps-icons-hr {
  margin: 0.8rem 0.8rem 0 0.8rem;
  background-color: #24292E;
  width: 2rem;
}

.grey-circle {
  color: #E5E7EA;
}

.icons-text {
  font-family: Inter;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 0em;
  text-align: center;
  color: #454C52;
}

.steps {
  margin-right: 0.6rem;
}

.steps-title {
  font-family: Inter;
  font-size: 1.16rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0em;
  margin-left: 0.8rem;
  margin-top: 1rem;
}

.type-selectors {
  justify-content: space-between;
  width: calc(100% - 1.2rem);
  margin: auto;
  margin-top: 1rem;
}

.my-2 {
  width: calc(100% - 1.2rem)!important;
  margin: auto;
  margin-bottom: 0rem !important;
  margin-top: 1.3rem !important;
}

.buttons {
  margin-top: 0;
}

.creation-panel {
  width: calc(100% - 1.2rem)!important;
  margin: auto;
  margin-top: 1rem;
}

.insight-type-button {
  width: 8rem;
  padding: 0.6rem;
  display: inline-block;
  margin: 2%;
  margin-right: 1.6rem;
}

@media screen and (max-width: 80rem) {
  .page-number {
    font-size: 0.8rem !important;
    margin-top: 0.8rem !important;
  }
  .insight-form-input {
    width: 2rem;
    height: 1rem;
  }
  .steps-title {
    margin-top: 0.3rem;
    font-size: 1rem;
  }
  .creation-panel {
    margin-top: 0.3rem;
  }
  .my-2 {
    margin-top: 0.8rem !important;
  }
}
</style>